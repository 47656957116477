import { useField } from "formik"
import React from "react"

const InputError = props => {
  const [field, meta] = useField(props.name)
  return (
    <div>
      {meta.error && meta.touched && (
        <div className="text-xs text-red-400 font-semibold">{meta.error}</div>
      )}
    </div>
  )
}

export default InputError
