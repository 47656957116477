import React from "react"

const Popup = ({ data, setDisplayPopup }) => {
  return (
    <div className="bg-blue-400 bg-opacity-75 w-full h-full absolute z-50">
      <div className="lg:max-w-2xl max-w-sm mx-auto lg:mt-10 mt-5 bg-white rounded-large lg:p-16 p-8 bg-opacity-100 flex justify-center flex-col items-center">
        <div
          className="w-full text-base text-center leading-6"
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
        <button
          onClick={() => setDisplayPopup(false)}
          className=" w-2/3 lg:w-1/3 bg-blue-400 hover:bg-gray-300 text-white text-lg py-3 rounded-full whitespace-nowrap mt-7 mb-0 focus:outline-none"
          type="button"
        >
          Continue
        </button>
      </div>
    </div>
  )
}

export default Popup
