import React, { useEffect, useState } from "react"
import { Formik } from "formik"
import { useStaticQuery, graphql } from "gatsby"
import * as Yup from "yup"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import InfoPart from "../components/investor-page/info-part"
import InvestForm from "../components/investor-page/invest-form"
import Popup from "../components/investor-page/popup"

const InvestorPage = () => {
  const investorPageSection = useStaticQuery(graphql`
    query Investor {
      allWpPage(filter: { id: { eq: "cG9zdDozNjE=" } }) {
        edges {
          node {
            InvestorPageInfoSection {
              investorPageInfoSectionPopup
              investorPageInfoSectionText
              investorPageInfoSectionTitle
              investorPageInfoSectionImg5 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              investorPageInfoSectionImg4 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              investorPageInfoSectionImg3 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              investorPageInfoSectionImg2 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              investorPageInfoSectionImg1 {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1300, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            InvestorPageFormSection {
              investorPageFormSectionText
              investorPageFormSectionTitle
              investorPageFormSectionHover1
              investorPageFormSectionHover2
            }
          }
        }
      }
    }
  `)

  const [loading,setLoading] = useState(false);
  const [response,setResponse] = useState(false);


  const investSchema = Yup.object().shape({
    first_name: Yup.string().required("This field is required"),
    last_name: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Enter a valid email"),
    phone: Yup.string().required("This field is required"),
    investor: Yup.string().required("This field is required"),
    term: Yup.boolean().oneOf(
      [true],
      "You must accept the terms and conditions"
    ),
    statement: Yup.boolean().oneOf([true], "You must read the statement"),
  })

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    investor: "",
    term: false,
    statement: false,
  }
  const [formSent,setFormSent] = useState('');
  const onSubmit = values => {
    setLoading(true);
    fetch("https://api.apispreadsheets.com/data/20791/", {
      method: "POST",
      body: JSON.stringify({
        data: [
          {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            phone: values.phone,
            status: values.investor,
          },
        ],
      }),
    }).then(res => {
      setLoading(false);
      if (res.status === 201) {
        setResponse(true)
      } else {
        setResponse(false)
      }
    })
  }

  const [displayPopup, setDisplayPopup] = useState(false)

  useEffect(() => {
    setTimeout(() => setDisplayPopup(true), 0)
  }, [])

  const infoSecion =
    investorPageSection.allWpPage.edges[0].node.InvestorPageInfoSection
  const formSecion =
    investorPageSection.allWpPage.edges[0].node.InvestorPageFormSection
  const popup = infoSecion.investorPageInfoSectionPopup

  return (
    <Layout>
      <SEO title="Investor page" />
      <Header class="main-container fixed w-full z-50 top-0" />
      <div className="main-container pt-20 lg:pt-24 relative">
        {displayPopup ? (
          <Popup data={popup} setDisplayPopup={setDisplayPopup} />
        ) : (
          <></>
        )}
        <InfoPart data={infoSecion} />
        <Formik
          initialValues={initialValues}
          validationSchema={investSchema}
          onSubmit={values => {
            onSubmit(values)
          }}
        >
          <InvestForm 
            data={formSecion} 
            loading={loading}  
            response={response}
          />
        </Formik>
        {formSent === 'sent' ? <div className="text-center text-xs text-blue-400 font-semibold text-left w-full  mt-3">Your email is sent.</div> : formSent === 'failed' ? <div className="text-xs text-red-400 font-semibold text-left w-full md:w-fit-content px-12 mt-3">Your  email is not sent. Please try again or contact us at hello@manusapp.com</div> : <div></div>}
      </div>
    </Layout>
  )
}

export default InvestorPage
