import React, { useEffect } from "react"
import Img from "gatsby-image"

const InfoPart = ({ data }) => {
  useEffect(() => {
    console.log(data)
  }, [])
  return (
    <div className="container px-3 md:px-6">
      <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
        <h2 className="text-4xl lg:text-6xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-12 ">
          {data.investorPageInfoSectionTitle}
        </h2>
        <p className="text-gray-600  text-sm-base lg:text-base leading-normal font-medium text-center mb-0">
          {data.investorPageInfoSectionText}
        </p>
      </div>
      <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10 lg:mb-0 mb-3">
        <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
        <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
      </div>
      <div className="mx-auto lg:w-5/12 w-full space-y-5 lg:py-10 py-3">
        <div>
          <Img
            fluid={
              data.investorPageInfoSectionImg1.localFile.childImageSharp.fluid
            }
            className="w-full"
          />
        </div>
        <div>
          <Img
            fluid={
              data.investorPageInfoSectionImg2.localFile.childImageSharp.fluid
            }
            className="w-full"
          />
        </div>
        <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10 lg:py-10 py-5">
          <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
          <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
        </div>
        <div>
          <Img
            fluid={
              data.investorPageInfoSectionImg3.localFile.childImageSharp.fluid
            }
            className="w-full"
          />
        </div>
        <div>
          <Img
            fluid={
              data.investorPageInfoSectionImg4.localFile.childImageSharp.fluid
            }
            className="w-full"
          />
        </div>
        <div className="flex text-center w-max mx-auto relative mt-5 lg:mt-10 lg:py-10 py-5">
          <div className="bg-green-400 h-2 w-12 rounded-full mr-4"></div>
          <div className="bg-blue-400 h-2 w-20 rounded-full"></div>
        </div>
      </div>
      <div>
        <Img
          fluid={
            data.investorPageInfoSectionImg5.localFile.childImageSharp.fluid
          }
          className="w-full h-full object-cover object-fit"
        />
      </div>
    </div>
  )
}

export default InfoPart
