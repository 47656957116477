import React, { useState } from "react"
import { Form, Field, useFormikContext } from "formik"
import InputError from "../common/inputError"

const InvestForm = ({ data, loading , response}) => {
  const { setFieldValue , isSubmitting} = useFormikContext()

  const [select, setSelect] = useState(0)
  const [hover, setHover] = useState(0)

  const selected =
    "bg-blue-400 text-white relative w-full flex flex-col justify-center items-center border-2 border-blue-400 px-8 py-6 rounded-large"
  const unselected =
    "bg-blue-white text-blue-400 relative w-full flex flex-col justify-center items-center border-2 border-blue-400 px-8 py-6 rounded-large"

  const infoSelected =
    "text-blue-400 bg-white relative rounded-full w-3 h-3 text-xxxs flex items-center justify-center mr-1 cursor-pointer"
  const infoUnSelected =
    "text-white bg-gray-300 relative rounded-full w-3 h-3 text-xxxs flex items-center justify-center mr-1 cursor-pointer"
  return (
    <div>
      <div className="container px-3 md:px-6">
        <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
          <h2 className="text-4xl lg:text-6xl text-blue-400 lg:text-blue-400 font-medium leading-tight text-center mb-2 lg:mb-5 mt-12 ">
            {data.investorPageFormSectionTitle}
          </h2>
          <div
            dangerouslySetInnerHTML={{
              __html: data.investorPageFormSectionText,
            }}
            className="text-gray-600  text-sm-base lg:text-base leading-normal font-medium text-center mb-0"
          ></div>
        </div>
      </div>
      <Form className="mb-0">
        <div className="container px-3 md:px-6">
          <div className="w-full lg:w-10/12 xl:w-2/3 mx-auto">
            <div className="text-sm text-gray-300 md:mb-8 mb-3">
              *All the files are mandatory files
            </div>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-x-20 gap-y-8 md:mb-10 mb-4">
              <div className="w-full lg:mr-6">
                <label className="text-gray-300 text-sm mr-4">First Name</label>
                <Field
                  type="text"
                  name="first_name"
                  id="first_name"
                  className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                />
                <InputError name="first_name" />
              </div>
              <div className="w-full lg:mt-0">
                <label className="text-gray-300 text-sm mr-4">Last Name</label>
                <Field
                  type="text"
                  name="last_name"
                  id="last_name"
                  className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                />
                <InputError name="last_name" />
              </div>

              <div className="w-full  lg:mr-6">
                <label className="text-gray-300 text-sm mr-4">
                  Email Adress
                </label>
                <Field
                  type="text"
                  name="email"
                  id="email"
                  className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                />
                <InputError name="email" />
              </div>

              <div className="w-full  lg:mt-0">
                <label className="text-gray-300 text-sm mr-4">
                  Mobile Number
                </label>
                <Field
                  id="phone"
                  name="phone"
                  className="w-full border border-gray-300 rounded-full py-3 px-7 mt-1 outline-none text-sm"
                />
                <InputError name="phone" />
              </div>
              <label className="text-gray-300 text-sm mr-4">
                Choose your status
              </label>
              <div></div>
              <div className="w-full lg:mt-0">
                <div
                  className={select === 1 ? selected : unselected}
                  onClick={() => {
                    setSelect(1)
                    setFieldValue("investor", "High net worth investor")
                  }}
                >
                  <div
                    className="absolute flex top-5 right-6 items-center"
                    onMouseEnter={() => {
                      setHover(1)
                      setFieldValue("statement", true)
                    }}
                    onMouseLeave={() => setHover(0)}
                  >
                    <div
                      className={select === 1 ? infoSelected : infoUnSelected}
                    >
                      i
                    </div>
                    <div
                      className={
                        select === 1
                          ? "text-xxxs text-white cursor-pointer"
                          : "text-xxxs text-gray-300 cursor-pointer"
                      }
                    >
                      Read statement
                    </div>
                    {hover === 1 ? (
                      <div
                        className="w-96 bg-white text-black leading-4 absolute top-5 lg:-left-40 -left-72 px-10 py-12 shadow-lg z-50 text-xs-s"
                        dangerouslySetInnerHTML={{
                          __html: data.investorPageFormSectionHover1,
                        }}
                        onMouseEnter={() => {
                          setHover(1)
                        }}
                        onMouseLeave={() => setHover(0)}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mb-2">
                    <svg
                      className="fill-active"
                      width="35"
                      height="37"
                      viewBox="0 0 35 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={
                          select === 1 ? "fill-active" : "fill-inactive"
                        }
                        d="M29.9922 22.2789L31.8594 17.7551C32.1719 16.9963 31.5703 16.1869 30.6875 16.1869H26.1172C26.9766 14.8211 27.5078 13.2529 27.5078 11.5619V11.5403C30.5703 10.9766 32.5078 10.16 32.5078 9.24943C32.5078 8.2883 30.375 7.43557 27.0312 6.86467C26.3125 4.49436 24.9219 2.10959 23.8594 0.881075C23.1172 0.0211146 21.8359 -0.246268 20.7734 0.245138L18.6172 1.2424C17.9141 1.5676 17.0859 1.5676 16.3828 1.2424L14.2266 0.245138C13.1641 -0.246268 11.8828 0.0211146 11.1406 0.881075C10.0859 2.10959 8.6875 4.49436 7.96875 6.86467C4.63281 7.43557 2.5 8.2883 2.5 9.24943C2.5 10.16 4.4375 10.9766 7.5 11.5403V11.5619C7.5 13.2529 8.03125 14.8211 8.89062 16.1869H4.39844C3.5 16.1869 2.89844 17.0324 3.25 17.7985L5.26562 22.1488C2.13281 23.8326 0 26.9328 0 30.5244V33.7619C0 35.5469 1.57031 36.9994 3.5 36.9994H31.5C33.4297 36.9994 35 35.5469 35 33.7619V30.5244C35 27.0268 32.9844 23.9916 29.9922 22.2789ZM13.75 34.6869L10.5 20.8119L14.375 23.1244L16.25 26.0151L13.75 34.6869ZM21.25 34.6869L18.75 26.0151L20.625 23.1244L24.5 20.8119L21.25 34.6869ZM24.5078 13.1156C24.2031 13.9756 23.9609 14.8934 23.2188 15.5293C22.4297 16.2014 19.4688 17.1481 18.2188 13.7227C18 13.1156 17.0156 13.1156 16.7891 13.7227C15.4609 17.3504 12.4141 16.0641 11.7891 15.5293C11.0469 14.8934 10.7969 13.9756 10.5 13.1156C10.4375 12.935 10.0078 12.7037 10.0078 12.6965V11.916C12.2188 12.1762 14.7734 12.3352 17.5078 12.3352C20.2422 12.3352 22.7969 12.1834 25.0078 11.916V12.6965C25 12.7037 24.5703 12.9278 24.5078 13.1156Z"
                        fill="#1D91E3"
                      />
                    </svg>
                  </div>
                  <div className="text-xl font-bold text-center">
                    High net worth investor
                  </div>
                  <div className="text-xs text-center leading-4 ">
                    I have an annual income of more than £100,000 or an
                    investment portfolio of more than £250,000
                  </div>
                </div>
                <InputError name="investor" />
              </div>

              <div className="w-full lg:mt-0">
                <div
                  className={select === 2 ? selected : unselected}
                  onClick={() => {
                    setSelect(2)
                    setFieldValue("investor", "Self certified Sophisticated Investor")
                  }}
                >
                  <div
                    className="absolute flex top-5 right-6 items-center"
                    onMouseEnter={() => {
                      setHover(2)
                      setFieldValue("statement", true)
                    }}
                    onMouseLeave={() => setHover(0)}
                  >
                    <div
                      className={select === 2 ? infoSelected : infoUnSelected}
                    >
                      i
                    </div>
                    <div
                      className={
                        select === 2
                          ? "text-xxxs text-white cursor-pointer"
                          : "text-xxxs text-gray-300 cursor-pointer"
                      }
                    >
                      Read statement
                    </div>
                    {hover === 2 ? (
                      <div
                        className="w-96 bg-white text-black leading-4 absolute top-5 lg:-left-40 -left-72 px-10 py-12 shadow-lg z-50 text-xs-s"
                        dangerouslySetInnerHTML={{
                          __html: data.investorPageFormSectionHover2,
                        }}
                        onMouseEnter={() => setHover(2)}
                        onMouseLeave={() => setHover(0)}
                      ></div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="mb-2">
                    <svg
                      width="32"
                      height="37"
                      viewBox="0 0 32 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={
                          select === 2 ? "fill-active" : "fill-inactive"
                        }
                        d="M16 18.5C21.05 18.5 25.1429 14.3592 25.1429 9.25C25.1429 4.14082 21.05 0 16 0C10.95 0 6.85714 4.14082 6.85714 9.25C6.85714 14.3592 10.95 18.5 16 18.5ZM22.8429 20.8559L19.4286 34.6875L17.1429 24.8594L19.4286 20.8125H12.5714L14.8571 24.8594L12.5714 34.6875L9.15714 20.8559C4.06429 21.1016 0 25.3146 0 30.525V33.5312C0 35.4463 1.53571 37 3.42857 37H28.5714C30.4643 37 32 35.4463 32 33.5312V30.525C32 25.3146 27.9357 21.1016 22.8429 20.8559Z"
                        fill="#1D91E3"
                      />
                    </svg>
                  </div>
                  <div className="text-xl font-bold text-center">
                    Self-certified Sophisticated Investor
                  </div>
                  <div className="text-xs text-center leading-4 ">
                    I have pervious experience of these or other alternative
                    investments, and understand my capital is at risk if I
                    invest.{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="md:mb-20 mb-5">
              <div className="w-full flex justify-center items-center">
                <Field type="checkbox" id="term" name="term" className="mr-2" />
                <label className="text-gray-300 text-sm mr-4" htmlFor="term">
                  I have selected the investor type which best describes me and
                  read the relevant statement in full.{" "}
                </label>
              </div>
              <div className="w-full flex justify-center items-center">
                <InputError name="term" />
              </div>
              <div className="w-full flex justify-center items-center">
                <InputError name="statement" />
              </div>
            </div>
            <div className="w-full lg:w-10/12 xl:w-1/3 mx-auto lg:mb-0 mb-10">
              {
                response && isSubmitting ?
                <div className="text-sm text-blue-400 font-semibold text-left w-full pb-3">Your email is sent</div>
                : null
              }
              <button
                disabled={ loading && isSubmitting ? true : false} 
                className={`w-full hover:bg-gray-300 text-white text-lg md:py-4 py-3 rounded-full whitespace-nowrap mb-0 focus:outline-none ${loading && isSubmitting ? 'bg-gray-300' : 'bg-blue-400'}`}
                type="submit"
              >
                {loading && isSubmitting ? "Submitting...": "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default InvestForm
